import Image from 'next/image'
import styles from './style.module.scss'
import LinkButton from 'src/components/button/LinkButton'
import { Routes } from 'src/utils/routes'
import { blurDataURL } from 'src/utils/blurDataURL'
import { FC } from 'react'

const AboutWahtIsPass: FC = ({}) => {
  const base64 = blurDataURL
  return (
    <>
      {/* <span className={styles.aboutWahtIsPassLine}>
        <Image
          src="/images/about_image_line.svg"
          width={932}
          height={385}
          alt=""
        />
      </span> */}

      <section className={styles.aboutWahtIsPassItem}>
        <div className={styles.aboutWahtIsPassItemImage}>
          <Image
            src="/images/about_preparation_image.png"
            width={446}
            height={420}
            alt=""
            loading="lazy"
            quality={100}
          />
        </div>
        <div className={styles.aboutWahtIsPassItemStack}>
          <h2 className={styles.aboutWahtIsPassItemHeading}>
            福岡への滞在が決まったら
            <br />
            パスの発行申請しよう
          </h2>
          <p className={styles.aboutWahtIsPassItemText}>
            ワフパスでは、お得に滞在できる会員限定の宿泊プランや来訪時に使える交通キャンペーンも掲載。ぜひ旅マエにチェックしてください！
          </p>
          <div className={styles.aboutWahtIsPassItemButton}>
            <LinkButton
              href={Routes.MY_PAGE}
              color="blue"
              size="lg:Small_mq:Large"
            >
              パス発行申請
            </LinkButton>
          </div>
          <p className={styles.aboutWahtIsPassItemBalloon}>
            <Image
              blurDataURL={base64}
              placeholder="blur"
              src="/images/about_preparation_balloon.svg"
              width={130}
              height={130}
              alt="画像 : 旅マエ"
            />
          </p>
        </div>
      </section>

      <section className={styles.aboutWahtIsPassItem}>
        <div className={styles.aboutWahtIsPassItemImage}>
          <Image
            blurDataURL={base64}
            placeholder="blur"
            src="/images/about_staying_image.png"
            width={446}
            height={420}
            alt=""
            loading="lazy"
            quality={100}
          />
        </div>

        <div className={styles.aboutWahtIsPassItemStack}>
          <p className={styles.aboutWahtIsPassItemBalloon}>
            <Image
              src="/images/about_staying_balloon.svg"
              width={130}
              height={130}
              alt="画像 : 旅ナカ"
            />
          </p>
          <h2 className={styles.aboutWahtIsPassItemHeading}>
            福岡滞在中に
            <br />
            お得な特典を利用しよう
          </h2>
          <p className={styles.aboutWahtIsPassItemText}>
            福岡に滞在予定のある方はフレンドリーパス申請フォームより利用申請をして、充実したワーケーションや長期滞在は楽しんでみませんか？
          </p>
          <div className={styles.aboutWahtIsPassItemButton}>
            <LinkButton
              href="/spot/page/1"
              color="blue"
              size="lg:Small_mq:Large"
            >
              特典一覧へ
            </LinkButton>
          </div>
        </div>
      </section>

      <section className={styles.aboutWahtIsPassItem}>
        <div
          className={`${styles.aboutWahtIsPassItemImage} ${styles.is_noshadow}`}
        >
          <Image
            blurDataURL={base64}
            placeholder="blur"
            src="/images/about_waf_image.png"
            width={130}
            height={130}
            alt="W@F work hard play more hard"
          />
        </div>

        <div className={styles.aboutWahtIsPassItemStack}>
          <h2 className={styles.aboutWahtIsPassItemHeading}>
            <em>W@F</em>では特典以外の
            <br />
            情報も発信中
          </h2>
          <p className={styles.aboutWahtIsPassItemText}>
            W@Fでは、特典以外の情報も充実しています。企業向けワーケーションプログラムやワーケーションに関する情報をお探しの方はW@Fもチェックしてください。
          </p>
          <div className={styles.aboutWahtIsPassItemButton}>
            <LinkButton
              href="https://workation-fukuoka.jp/"
              color="blue"
              size="lg:Small_mq:Large"
            >
              W@F
            </LinkButton>
          </div>
        </div>
      </section>
      <section className={styles.aboutWahtIsPassItem}>
        <div className={styles.aboutWahtIsPassItemStack}>
          <h2 className={styles.aboutWahtIsPassItemHeading}>
            <em>九州広域</em>の特典も掲載開始！
          </h2>
          <p className={styles.aboutWahtIsPassItemText}>
            ワフパスは、福岡市だけでなく九州全域にご利用範囲を拡大します。福岡滞在のついでに、少し足を伸ばして他県でもワーケーションすることができたり、九州の周遊旅にワフパスを利用することでお得に旅ができたり。充実した九州の旅をお過ごしください。
          </p>
          <div className={styles.aboutWahtIsPassItemButton}>
            <LinkButton
              href="https://workation-fukuoka.jp/contact/"
              color="blue"
              size="lg:Small_mq:Large"
            >
              掲載希望の方はこちら
            </LinkButton>
          </div>
        </div>
        <div
          className={`${styles.aboutWahtIsPassItemImage} ${styles.is_noshadow}`}
        >
          <Image
            blurDataURL={base64}
            placeholder="blur"
            src="/images/about_expansion_area.svg"
            width={130}
            height={130}
            alt="九州エリアに拡大"
          />
        </div>
      </section>
    </>
  )
}

export { AboutWahtIsPass }
