import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Lazy } from 'swiper'
import 'swiper/css/bundle'

import styles from './style.module.scss';
import { WafFriendlyPass } from 'src/types/waf-friendly-pass';
import dynamic from 'next/dynamic';
SwiperCore.use([Lazy])

const DynamicServiceCard = dynamic(() => import('src/components/service/card'), {
  suspense: true,
})

type Props = {
  services: WafFriendlyPass.service[]
}
const AboutServiceCarousel: React.VFC<Props> = ({ services }) => {
  return (
    <div className={styles.sboutServiceCarousel}>
      <Swiper
        modules={[Lazy]}
        observer
        slidesPerView="auto"
        spaceBetween={16}
        loop={true}
        loopedSlides={services.length + 2}
        loopAdditionalSlides={1}
        speed={1000}
        lazy={true}
        watchOverflow
        className={styles.sboutServiceCarouselContainer}
        initialSlide={3}
      >
        {
          services.map((service, idx) => {
            return (
              <SwiperSlide key={`${idx}`} className={styles.sboutServiceCarouselSlide}>
                <DynamicServiceCard service={service} key={idx} single />
              </SwiperSlide>
            )
          })
        }
      </Swiper>
    </div>
  )
}

export { AboutServiceCarousel }
