import { WafFriendlyPass } from 'src/types/waf-friendly-pass'
import Image from 'next/image'
import styles from './style.module.scss'
import Link from 'next/link'

type Props = {
  item: WafFriendlyPass.recommend
}

const CarouselRecommendWafCard: React.VFC<Props> = ({ item }) => {
  return (
    <article className={styles.carouselRecommendWafCard}>
      <div className={styles.carouselRecommendWafCardImage}>
        <Image
          src={item.acf.image.url}
          layout="responsive"
          width={335}
          height={240}
          alt={item.acf.image.title}
        />
      </div>
      <div className={styles.carouselRecommendWafCardStack}>
        <p className={styles.carouselRecommendWafCardLabel}>
          {item.acf.en_text}
        </p>
        <h3 className={styles.carouselRecommendWafCardHeading}>
          {item.acf.ja_text}
        </h3>
        <p
          className={styles.carouselRecommendWafCardDesc}
          dangerouslySetInnerHTML={{ __html: item.acf.desc }}
        />
        <div className={styles.carouselRecommendWafCardLink}>
          <Link href={item.acf.url} passHref>
            <a target="_blank" rel="noreferrer noopener">
              詳しく見る
            </a>
          </Link>
        </div>
      </div>
    </article>
  )
}

export { CarouselRecommendWafCard }
