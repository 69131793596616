/**
const url =
  process.env.NEXT_PUBLIC_APP_ENV === 'production'
    ? 'https://api.workation-fukuoka.jp'
    : 'http://localhost:3001'
*/
const url = 'https://api.workation-fukuoka.jp'

const wpApiUrl = {
  officialRecommend: `${url}/wp-json/wp/v2/official_recommend?_fields=id,acf`,
  news: `${url}/wp-json/wp/v2/news`,
  campaign: `${url}/wp-json/wp/v2/campaign`,
  spot: `${url}/wp-json/wp/v2/spot`,
  service: `${url}/wp-json/wp/v2/service`,
  hotel: `${url}/wp-json/wp/v2/hotel`,
  terms: `${url}/wp-json/wp/v2/pages/17`,
  privacypolicy: `${url}/wp-json/wp/v2/pages/23`,
  taxonomySpotType: `${url}/wp-json/wp/v2/spot_type`,
  taxonomyArea: `${url}/wp-json/wp/v2/area`,
  taxonomySpotFeature: `${url}/wp-json/wp/v2/spot_feature`,
  taxonomyHotelFeature: `${url}/wp-json/wp/v2/hotel_feature`,
  allPosts: `${url}/wp-json/wp/v2/allPosts`,
  passRequest: `${url}/wp-json/wp/v2/pages/1032/?acf_format=standard`,
}

export { wpApiUrl }
