import { useState } from 'react'
import styles from './style.module.scss'

type Props = {
  a: string
  q: string
}

const FaQItem: React.VFC<Props> = ({ q, a }) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleToggle = () => {
    return setIsOpen(!isOpen)
  }

  return (
    <dl className={styles.FaQItem}>
      <dt
        data-active={isOpen}
        onClick={() => {
          handleToggle()
        }}
        className={styles.FaQItemQuestion}
      >
        <span>{q}</span>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path
            d="M12 15L12.7071 15.7071L12 16.4142L11.2929 15.7071L12 15ZM18.7071 9.70711L12.7071 15.7071L11.2929 14.2929L17.2929 8.29289L18.7071 9.70711ZM11.2929 15.7071L5.29289 9.70711L6.70711 8.29289L12.7071 14.2929L11.2929 15.7071Z"
            fill="#282828"
          />
        </svg>
      </dt>
      <dd
        data-active={isOpen}
        className={styles.FaQItemAnswer}
        dangerouslySetInnerHTML={{ __html: a }}
      />
    </dl>
  )
}

export { FaQItem }
