import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Pagination, Lazy } from 'swiper'
import { WafFriendlyPass } from 'src/types/waf-friendly-pass'
import { CarouselRecommendWafCard } from './card';

import 'swiper/css/bundle'
import styles from './style.module.scss';

SwiperCore.use([Lazy])

type Props = {
  items: WafFriendlyPass.recommend[]
}

const CarouselRecommendWaf: React.VFC<Props> = ({ items }) => {
  return (
    <>
      {
        items.length > 3
          ?
          <Swiper
            modules={[Lazy]}
            slidesPerView="auto"
            spaceBetween={16}
            loop={true}
            loopedSlides={items.length + 2}
            loopAdditionalSlides={1}
            speed={1000}
            lazy={true}
            className={styles.carouselRecommendContainer}
            breakpoints={{
              828: {
                watchOverflow: true,
                centeredSlides: true,
              }
            }}
          >
            {items.map((item, index) => {
              return (
                <SwiperSlide key={`${index}`} className={styles.carouselRecommendSlide}>
                  <CarouselRecommendWafCard item={item} />
                </SwiperSlide>
              )
            })}
          </Swiper>
          :
          <div className={styles.carouselRecommendList}>
            {
              items.map((item, index) => {
                return (
                  <div key={index} className={styles.carouselRecommendCard}>
                    <CarouselRecommendWafCard item={item} />
                  </div>
                )
              })
            }
          </div>
      }
    </>
  )
}

export { CarouselRecommendWaf }
