const faqItems = [
  {
    q: 'PC端末から利用はできますか？',
    a: `二次元バーコードを読み込む都合上PC端末からのご利用はできません。<br />
    現地でパスをご利用の際はスマートフォン端末からアクセスしてご利用してください。`,
  },
  {
    q: '発行したパス画面に有効期限はありますか？',
    a: `パスの有効期限は、滞在期間中となります。（チェックイン日の0:00~チェックアウト日の23:59まで）`,
  },
  {
    q: 'パス申請の承認にどのくらいの時間がかかりますか？',
    a: `パス発行申請後、承認までに通常2-3日お時間を頂いております。ご旅程が決まり次第お早めに発行申請をお願いいたします。余裕を持った手続きをお願いします。`,
  },
  {
    q: 'パスを誰かと共有はできますか？団体での利用はできますか？',
    a: `共有はできませんので、お1人ずつパスの発行をお願いいたします。ただし、団体利用を前提としているサービス（研修プログラムなど）については、代表者のみのパス発行でサービスを受けることができます。`,
  },
  {
    q: 'パスを発行する際の宿泊先は福岡県・福岡市以外でも良いですか？',
    a: `<strong>福岡市内の宿泊施設であることが必須です。</strong>市内以外の宿泊施設や、実家・友人宅・寮などに宿泊する場合は承認できませんのでご注意ください。`,
  },
]

export { faqItems }
