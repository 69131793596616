import { faqItems } from 'src/utils/faq'
import { FaQItem } from '../item'
import styles from './style.module.scss'
import LinkButton from 'src/components/button/LinkButton'

const FaQList: React.VFC = () => {
  return (
    <>
      <div className={styles.FaQList}>
        {faqItems.map((item, idx) => {
          return <FaQItem key={idx} q={item.q} a={item.a} />
        })}
        <div className={styles.FaQListButton}>
          <LinkButton href="/faq" size="lg:Small_mq:Large" color="blue">
            もっとみる
          </LinkButton>
        </div>
      </div>
    </>
  )
}

export { FaQList }
